import { Filter, FilterBroker } from '@/interfaces/filter';
import { Mixins, Component, Watch } from 'vue-property-decorator';
import IsUser from '@/mixins/is-user';
@Component
export default class FilterTableMixin extends Mixins(IsUser) {
    @Watch('$route.params.state', {
        deep: true,
        immediate: true
    })
    handleRoute(state: string) {
        this.filterData({
            state: state,
            brokerId: this.isUser ? undefined : true,
            typeFilter:
                this.$route.query.typeFilter
                    ? (this.$route.query.typeFilter as string)
                    : undefined,
            filterValue:
                this.$route.query.filterValue
                    ? (this.$route.query.filterValue as string)
                    : undefined,
            page: this.$route.query.page
                ? parseInt(this.$route.query.page as string)
                : 1
        });
    }

    get classPrimary() {
        return this.isUser
            ? 'bg-primary text-white'
            : 'bg-success-2 text-white';
    }

    changeState(state: string) {
        this.$router.push({ params: { state }, query: { } });
    }

    filterData(filter: Filter & FilterBroker & { state: string }) {
        console.log('');
    }
}
