




















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import AutoCompleteCustomer from '@/components/AutoCompleteCustomer.vue';
import AutoComplete from '@/components/AutoComplete.vue';
import { builderModule } from '@/store/modules/builder';
import { agentModule } from '@/store/modules/agent';
import { customerModule } from '@/store/modules/customer';

@Component({
    components: { AutoCompleteCustomer, AutoComplete }
})
export default class FilterTable extends Vue {
    @Prop({ type: String, default: 'bg-info-2' })
    readonly classPrimary!: string;
    @Prop() blocked!: string[];

    filterValue: string | number[] = '';
    typeFilter = 'todos';
    firstSearchUsers = true;
    firstSearchCustomers = true;

    options = [
        {
            value: 'todos',
            label: 'Todos'
        },
        {
            value: 'user',
            label: 'Agente'
        },
        {
            value: 'customer',
            label: 'Prospecto'
        },
        {
            value: 'dni',
            label: 'DNI'
        },
        {
            value: 'pas',
            label: 'Pasaporte'
        },
        {
            value: 'ce',
            label: 'Carnet de Extranjería'
        },
        {
            value: 'inmobiliaria',
            label: 'Inmobiliaria'
        },
        {
            value: 'fecha',
            label: 'Rango de fecha'
        },
        {
            value: 'cliente',
            label: 'Apellidos'
        },
        {
            value: 'email',
            label: 'Email'
        }
    ];

    mounted() {
        if (
            this.$route.query.typeFilter &&
            this.filters.find(
                ({ value }) => value === this.$route.query.typeFilter
            )
        ) {
            this.typeFilter = this.$route.query.typeFilter as string;
        }
    }

    get filters() {
        if (!this.blocked) return this.options;
        else
            return this.options.filter(
                ({ value }) => !this.blocked.includes(value)
            );
    }

    getBuilders(queryString: string, cb: Function) {
        builderModule.index(queryString).then(data => {
            cb(data);
        });
    }

    getUsers(queryString: string, cb: Function) {
        agentModule
            .getChildsByName(this.firstSearchUsers ? undefined : queryString)
            .then(data => {
                this.firstSearchUsers = false;
                cb(data);
            });
    }

    getCustomers(queryString: string, cb: Function) {
        customerModule
            .getCustomersOfUser(
                this.firstSearchCustomers
                    ? { brokerId: true }
                    : { fullName: queryString, brokerId: true }
            )
            .then(data => {
                this.firstSearchCustomers = false;
                cb(data);
            });
    }

    selectUser(user: any) {
        this.setFilter({
            typeFilter: this.typeFilter,
            filterValue: user._id
        });
    }

    selectCustomer(customer: any) {
        this.setFilter({
            typeFilter: this.typeFilter,
            filterValue: customer._id
        });
    }

    selectBuilder(builder: any) {
        this.setFilter({
            typeFilter: this.typeFilter,
            filterValue: builder.builder_id
        });
    }

    handleSelect() {
        this.filterValue = this.typeFilter === 'fecha' ? [] : '';
        if (this.typeFilter === 'todos') {
            this.setFilter({
                typeFilter: this.typeFilter,
                filterValue: this.filterValue
            });
        }
    }

    setFilter(data: any) {
        if (this.$route.query.typeFilter) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    typeFilter: undefined,
                    filterValue: undefined
                }
            });
        }
        this.$emit('typeTable', data);
    }
}
