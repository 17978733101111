import { Vue, Component } from 'vue-property-decorator';

@Component
export default class IsUser extends Vue {
    get isUser() {
        return !['BrokerAppointment', 'BrokerMinute'].includes(
            this.$route.name as string
        );
    }
}
